<template>
  <div class="sovi-contracts pb-4 pt-7 md:pt-3">
    <sovi-free-signatures @identify="identify = true" />

    <div class="sovi-contracts__header flex items-end pb-2">
      <sovi-bread-crumbs
        class="relative z-1 mr-1"
        @crumb-click="onCrumbClick"
      />

      <v-spacer />

      <div class="flex size-10 flex-none">
        <div
          ref="contractSearchRef"
          data-step="search"
          :class="{
            'inset-x-0 z-10 w-full sm:left-auto sm:max-w-96': searchOpen,
            'z-0': !searchOpen,
            'background pointer-events-none !z-[11] rounded-full bg-light-200 dark:bg-dark-700':
              isStepActive('welcomeTour', 'search'),
          }"
          class="sovi-contracts__search absolute inset-x-0 h-10 w-full sm:left-auto sm:max-w-96"
        >
          <sovi-search
            :placeholder="t('searchContracts')"
            @open="onSearchOpen"
          />
        </div>
      </div>
    </div>

    <v-alert
      v-if="folderMissing"
      variant="tonal"
      type="info"
      border="start"
      color="tertiary"
    >
      {{ t('folderDoesNotExist') }}
    </v-alert>

    <v-card v-else class="overflow-visible">
      <div
        v-if="!hideControls"
        class="sovi-company-users__card-title title flex items-center justify-between p-4 sm:py-6"
      >
        <div class="flex w-full items-center justify-between">
          <v-btn
            ref="newContractButtonRef"
            data-step="create"
            color="primary"
            :class="{
              'pointer-events-none z-[11]': isStepActive(
                'welcomeTour',
                'create',
              ),
            }"
            @click="onAddContractClick"
          >
            {{ t('newContract') }}
          </v-btn>

          <div
            ref="sortRef"
            data-step="sort"
            class="flex gap-2"
            :class="{
              'pointer-events-none z-[11] -mx-2 -my-1 rounded-full bg-light-200 px-2 py-1 transition-all dark:bg-dark-700':
                isStepActive('welcomeTour', 'sort'),
            }"
          >
            <v-icon-btn
              color="tertiary"
              icon="mdi-delete-outline"
              :to="{ path: '/contracts/1' }"
            />

            <v-icon-btn
              color="tertiary"
              icon="mdi-folder-plus-outline"
              @click="showFolderAddDialog = true"
            />

            <sovi-contract-menu ref="menuRef" @filter="onFilter($event)" />
          </div>
        </div>
      </div>

      <v-card-text
        ref="listParentRef"
        class="sovi-contracts-list__contracts px-4 py-0 pb-8"
      >
        <div v-if="filtering.length" class="mt-0 pb-3 md:-mt-3">
          <v-chip
            color="tertiary"
            variant="outlined"
            closable
            rounded
            @click="reset()"
            @click:close="reset()"
          >
            <span v-for="(status, idx) in filtering" :key="status">
              {{ `${t(status)}${idx < filtering.length - 1 ? ',&nbsp;' : ''}` }}
            </span>
          </v-chip>
        </div>

        <sovi-contract-list v-auto-animate :class="{ 'mt-8': hideControls }">
          <sovi-contract-list-item
            v-for="folder in folders"
            :key="`sovi-folder-${folder.id}-parent`"
            :item="folder as DragItem"
            :active="isDragEnabled"
            type="folder"
            draggable
            droppable
            @select="onClick($event.action, $event.item, 'folder')"
          />

          <sovi-contract-list-item
            v-for="contract in sortedContracts"
            :key="`sovi-contract-${contract.id}-parent`"
            :active="isDragEnabled"
            :item="{ ...contract, type: 'contract' } as DragItem"
            draggable
            @select="onClick($event.action, $event.item, 'contract')"
          />
        </sovi-contract-list>

        <v-list-item
          v-if="
            !sortedContracts.length &&
            !folders.length &&
            !isLoading('getContracts')
          "
        >
          {{ t('noData') }}
        </v-list-item>

        <div
          v-auto-animate
          :class="{ 'mt-4 flex justify-center': !isFullyLoaded }"
        >
          <v-btn
            v-if="!isFullyLoaded"
            variant="elevated"
            :disabled="isLoading('getContracts')"
            :loading="isLoading('getContracts')"
            color="tertiary"
            @click="onLoadMore()"
          >
            {{ t('loadMore') }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <sovi-folder-move-confirmation-dialog />

    <sovi-contract-add-dialog />

    <sovi-folder-add-dialog
      :display="showFolderAddDialog"
      @close="showFolderAddDialog = false"
    />

    <sovi-identify-dialog v-model="identify" @close="identify = false" />

    <sovi-folder-edit-dialog
      :display="currentAction === 'edit'"
      :item="editedItem"
      @close="onClose"
    />

    <sovi-folder-delete-dialog
      :display="currentAction === 'delete'"
      :item="editedItem"
      @close="onClose"
      @delete="onDelete()"
    />

    <sovi-welcome-dialog
      :display="showWelcomeDialog"
      @cancel="showWelcomeDialog = false"
      @submit="showWelcomeDialog = false"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import { sleep } from '@/utils';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useContractStore } from '@/stores/contract';
import { useAuthorizationStore } from '@/stores/authorization';
import { useCreditStore } from '@/stores/credit';
import { useNetsStore } from '@/stores/nets';
import { useRoute, useRouter } from 'vue-router';
import { usePackageStore } from '@/stores/package';
import type { VBtn, VCardText, VMenu } from 'vuetify/components';
import { useLoadingStore } from '@/stores/loading';
import type { DragItem } from '@/types/dragging';
import type { Folder } from '@/types/contract';
import { useOnboardingStore } from '@/stores/onboarding';
import { definePage } from 'vue-router/auto';

import type SoviContractListItem from '@/components/contract/SoviContractListItem.vue';
import { useAppStore } from '@/stores/app';

definePage({
  name: 'Contracts',
  meta: {
    preloaders: ['preloadContracts'],
    hero: true,
    restricted: true,
    tour: 'welcomeTour',
  },
});

const { isStepActive } = useOnboardingStore();

const { showWelcomeDialog } = storeToRefs(useOnboardingStore());

const { showBusinessFeaturesDialog } = storeToRefs(useAppStore());

const { isLoading } = useLoadingStore();

const { t } = useI18n();

const newContractButtonRef = ref<HTMLElement | null>(null);

const contractSearchRef = ref<HTMLElement | null>(null);

const sortRef = ref<HTMLElement | null>(null);

const isDragEnabled = ref(true);

const moveType = ref('contract');

const identify = ref(false);

const currentAction = ref('');

const editedItem = ref<Folder>({} as Folder);

const searchOpen = ref(false);

const showFolderAddDialog = ref(false);

const page = ref(1);

const pageSize = ref(25);

const filtering = ref<string[]>([]);

const { folders, contracts, contractCount, showAddContractDialog } =
  storeToRefs(useContractStore());

const { list: getContracts } = useContractStore();

const { getPackages, showPackageDialog } = usePackageStore();

const { user } = storeToRefs(useAuthorizationStore());

const { total: credit } = storeToRefs(useCreditStore());

const { identificationDialog: contractId } = storeToRefs(useNetsStore());

const isFullyLoaded = computed(
  () => contractCount.value === Object.keys(contracts.value).length,
);

const numberOfPages = computed(
  () => Math.ceil(contractCount.value / pageSize.value) ?? 1,
);

/**
 * Contracts sorted from newest to oldest
 */
const sortedContracts = computed(() => {
  return Object.entries(contracts.value)
    .map(([id, contract]) => ({
      ...contract,
      id,
    }))
    .sort((a, b) => {
      if (a.created_at > b.created_at) return 1;

      if (a.created_at < b.created_at) return -1;

      return 0;
    })
    .reverse()
    .filter((contract) =>
      !filtering.value.length
        ? contract.id
        : filtering.value.includes(contract.status),
    );
});

const onFilter = (statuses: { value: string; title: string }[]) => {
  filtering.value = statuses
    .filter((status) => status.value)
    .map((status) => status.title);
};

const onAddContractClick = async () => {
  if (!user.value.is_identified) {
    identify.value = true;

    return;
  }

  if (!credit.value) {
    await getPackages();

    showPackageDialog({
      showCreditMessage: true,
    });

    return;
  }

  showAddContractDialog.value = true;
};

const onCrumbClick = () => {
  page.value = 1;
};

const onClick = (action: string, item: Folder, type = 'contract') => {
  page.value = 1;
  moveType.value = type;
  currentAction.value = action;
  editedItem.value = { ...item };
};

const hideControls = computed(() => +route.params.folderId === 1);

const onClose = async () => {
  currentAction.value = '';

  await sleep(500);

  editedItem.value = {} as Folder;
};

const onDelete = async () => {
  currentAction.value = '';

  await sleep(500);

  editedItem.value = {} as Folder;

  page.value = 1;

  load(false);
};

const menuRef = ref<typeof VMenu | null>(null);

const listParentRef = ref<typeof VCardText | null>(null);

const reset = () => {
  menuRef.value?.reset();
};

const route = useRoute();

const router = useRouter();

const { breadCrumbs } = storeToRefs(useContractStore());

const crumbs = computed(() => {
  const { folderId } = route.params;

  return breadCrumbs.value.map((crumb) => ({
    ...crumb,
    disabled:
      breadCrumbs.value.length === 1 ||
      (folderId ? +(crumb.id || 0) === +folderId : crumb.disabled),
  }));
});

const folderMissing = computed(
  () => crumbs.value.length === 1 && route.params.folderId,
);

onMounted(() => {
  if (route.query?.action === 'businessFeatures') {
    const returnUrl = sessionStorage.getItem('businessFeaturesReturnUrl');

    router.replace(returnUrl || { query: {} });

    requestAnimationFrame(() => {
      showBusinessFeaturesDialog.value = true;
    });
  }
});

watch(
  contractId,
  (id) => {
    identify.value = !!id;
  },
  { immediate: true },
);

const load = (merge = true) => {
  getContracts({
    folderId: (route.params.folderId as string) ?? null,
    page: page.value,
    limit: pageSize.value,
    merge,
  });
};

const onLoadMore = () => {
  if (page.value < numberOfPages.value) {
    page.value += 1;

    load();
  }
};

const onSearchOpen = (open: boolean) => {
  searchOpen.value = open;
};
</script>

<style lang="scss">
.theme--dark {
  .sovi-contracts {
    &-list {
      &__contracts {
        background-repeat: no-repeat;
        background-size:
          100% 40px,
          100% 40px,
          100% 14px,
          100% 14px;
        // shadow without headers -----------------------------
        background-position:
          left top,
          left bottom;
        background-attachment: local, local, scroll, scroll;
      }
    }
  }
}

.sovi-contracts {
  position: relative;

  &__options {
    display: grid;
    padding: 0 12px;
    align-items: center;
  }

  &__header {
    flex-wrap: nowrap;
    position: relative;
  }

  &__title {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }

  &__label {
    position: sticky;
    top: -16px;
    z-index: 1;
    margin-bottom: 0 !important;
    padding: 16px 24px 0 24px;
    margin: -16px -16px 0 -16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__search {
    &--open {
      max-width: 400px;
      width: 100%;
    }
  }

  &-list {
    display: grid;
    gap: 4px;

    &__contracts {
      display: grid;
      gap: 8px;

      background-repeat: no-repeat;
      background-size:
        100% 40px,
        100% 40px,
        100% 14px,
        100% 14px;
      background-position:
        left top,
        left bottom;
      background-attachment: local, local, scroll, scroll;
    }

    &__actions {
      display: grid;
      gap: 8px;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
    }
  }

  .v-list-item {
    transition:
      opacity 200ms ease-in-out,
      scale 200ms ease-in-out;

    &.is-dragged {
      opacity: 0.3;
      scale: 0.99;
    }
  }

  .sticky {
    gap: 8px;
    z-index: 1;
    flex-wrap: wrap;

    .v-btn {
      margin: 0 !important;
    }
  }
}
</style>

<i18n lang="json">
{
  "fi": {
    "folderDoesNotExist": "Kansiota ei ole olemassa",
    "move": "Siirrä",
    "newContract": "Uusi sopimus",
    "newFolder": "Uusi kansio",
    "noData": "Ei sopimuksia",
    "noContracts": "Ei avoimia sopimuksia",
    "folders": "Kansiot",
    "contracts": "Sopimukset",
    "roleauthor": "Laatija",
    "roleobserver": "Tarkkailija",
    "roleparticipant": "Sopimusosapuoli",
    "searchContracts": "Etsi...",
    "itemsPerPage": "Sopimuksia sivulla",
    "currentPage": "Sivu {page} / {numberOfPages}",
    "total": "Yhteensä",
    "showing": "Näytetään sopimukset",
    "edit": "Muokkaa",
    "Contracts": "Omat sopimukset",
    "companySettings": "Hallinta",
    "ready": "Muokkaus valmis",
    "loadMore": "Lataa lisää",
    "loadingContracts": "Ladataan sopimuksia...",
    "created": "Luonnos",
    "sent": "Odottaa allekirjoituksia",
    "finished": "Allekirjoitettu",
    "failed": "Rauennut",

    "onboarding": {
      "create": {
        "title": "Luo uusia sopimuksia",
        "content": "Voit luoda uuden sopimuksen tästä"
      },
      "sort": {
        "title": "Järjestä",
        "content": {
          "createFolders": "Luo kansioita",
          "filterContracts": "Suodata sopimuksia"
        }
      },
      "search": {
        "title": "Etsi",
        "content": "Voit etsiä sopimuksia ja kansioita tästä"
      },
      "settings": {
        "title": "Mukauta asetuksiasi",
        "content": {
          "buyCredits": "Osta allekirjoituskrediittejä",
          "viewEvents": "Näytä tapahtumat",
          "manageSettings": "Muokkaa asetuksia"
        }
      }
    },

    "welcomeTour-dialog": {
      "title": "Tervetuloa Sovi.fi-palveluun",
      "content": "Lähetä sopimukset allekirjoitettavaksi hetkessä."
    }
  },
  "en": {
    "folderDoesNotExist": "Folder does not exist",
    "move": "Move",
    "newContract": "New contract",
    "newFolder": "New Folder",
    "noData": "No contracts",
    "noContracts": "No open contracts",
    "folders": "Folders",
    "contracts": "Contracts",
    "roleauthor": "Author",
    "roleobserver": "Spectator",
    "roleparticipant": "Contracting party",
    "searchContracts": "Search...",
    "itemsPerPage": "Contracts per page",
    "currentPage": "Page {page} of {numberOfPages}",
    "total": "Total",
    "showing": "Showing contracts",
    "edit": "Edit",
    "Contracts": "My Contracts",
    "companySettings": "Settings",
    "ready": "Finished editing",
    "loadMore": "Load more",
    "loadingContracts": "Loading contracts...",
    "created": "Draft",
    "sent": "Waiting for signatures",
    "finished": "Signed",
    "failed": "Rejected",

    "onboarding": {
      "create": {
        "title": "Create new contracts",
        "content": "New contracts can be created here"
      },
      "sort": {
        "title": "Arrange",
        "content": {
          "createFolders": "Create folders",
          "filterContracts": "Filter contracts"
        }
      },
      "search": {
        "title": "Search",
        "content": "You can search for contracts and folders here"
      },
      "settings": {
        "title": "Modify the settings",
        "content": {
          "buyCredits": "Buy signature credits",
          "viewEvents": "View events",
          "manageSettings": "Manage settings"
        }
      }
    },

    "welcomeTour-dialog": {
      "title": "Welcome to Sovi.fi",
      "content": "Get signatures for your contracts with ease."
    }
  }
}
</i18n>
