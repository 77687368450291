<template>
  <v-dialog
    :overlay-opacity="0.95"
    :model-value="show"
    content-class="sovi-providers"
    dark
    persistent
    scrollable
  >
    <div
      class="mx-auto w-full max-w-screen-xl overflow-auto px-2 pt-0 sm:px-4 md:px-8"
    >
      <div class="sovi-providers__title mb-4 flex justify-between">
        <p class="white--text text-2xl font-semibold">
          {{ t('selectPaymentMethod') }}
        </p>

        <v-icon-btn
          class="white text-none"
          icon="mdi-close"
          @click="emit('close')"
        />
      </div>

      <div class="sovi-providers__items pb-4">
        <sovi-payment-provider
          v-for="(provider, index) in providers"
          :key="index"
          :index="index"
          :provider="provider"
        />
      </div>
    </div>
  </v-dialog>
</template>

<script lang="ts" setup>
import type { Provider } from '@/types/checkout';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const emit = defineEmits<{ close: [] }>();

withDefaults(
  defineProps<{
    providers: Provider[];
    show?: boolean;
  }>(),
  {
    show: false,
  },
);
</script>

<style lang="scss">
.sovi-providers {
  margin: 0;
  max-height: 100svh !important;
  border-radius: 0;
  padding: 16px;
  overflow-y: scroll !important;

  &__items {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    place-content: center;
  }

  &__title {
    opacity: 0;
    transform: translateY(16px);
    animation: 600ms cubic-bezier(0, 0, 0.2, 1) 500ms forwards fadeIn;
  }
}
</style>

<i18n lang="json">
{
  "fi": {
    "selectPaymentMethod": "Valitse maksutapa"
  },
  "en": {
    "selectPaymentMethod": "Select a payment method"
  }
}
</i18n>
