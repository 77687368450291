import _definePage_default_0 from '/tmp/build_a585c7f8/src/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/tmp/build_a585c7f8/src/pages/[...path].vue?definePage&vue'
import _definePage_default_3 from '/tmp/build_a585c7f8/src/pages/admin-view.vue?definePage&vue'
import _definePage_default_4 from '/tmp/build_a585c7f8/src/pages/billing-history.vue?definePage&vue'
import _definePage_default_5 from '/tmp/build_a585c7f8/src/pages/company-settings.vue?definePage&vue'
import _definePage_default_6 from '/tmp/build_a585c7f8/src/pages/contract/[contractId].vue?definePage&vue'
import _definePage_default_7 from '/tmp/build_a585c7f8/src/pages/contracts/[[folderId]].vue?definePage&vue'
import _definePage_default_8 from '/tmp/build_a585c7f8/src/pages/magic-link/[hash].vue?definePage&vue'
import _definePage_default_9 from '/tmp/build_a585c7f8/src/pages/templates/management.vue?definePage&vue'
import _definePage_default_10 from '/tmp/build_a585c7f8/src/pages/verify/[hash].vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: 'Login',
    component: () => import('/tmp/build_a585c7f8/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/:path(.*)',
    name: '404',
    component: () => import('/tmp/build_a585c7f8/src/pages/[...path].vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  _mergeRouteRecord(
  {
    path: '/admin-view',
    name: 'AdminView',
    component: () => import('/tmp/build_a585c7f8/src/pages/admin-view.vue'),
    /* no children */
  },
  _definePage_default_3
  ),
  _mergeRouteRecord(
  {
    path: '/billing-history',
    name: 'BillingHistory',
    component: () => import('/tmp/build_a585c7f8/src/pages/billing-history.vue'),
    /* no children */
  },
  _definePage_default_4
  ),
  _mergeRouteRecord(
  {
    path: '/company-settings',
    name: 'CompanySettings',
    component: () => import('/tmp/build_a585c7f8/src/pages/company-settings.vue'),
    /* no children */
  },
  _definePage_default_5
  ),
  {
    path: '/contract',
    /* internal name: '/contract' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':contractId',
        name: 'Contract',
        component: () => import('/tmp/build_a585c7f8/src/pages/contract/[contractId].vue'),
        /* no children */
      },
  _definePage_default_6
  )
    ],
  },
  {
    path: '/contracts',
    /* internal name: '/contracts' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':folderId?',
        name: 'Contracts',
        component: () => import('/tmp/build_a585c7f8/src/pages/contracts/[[folderId]].vue'),
        /* no children */
      },
  _definePage_default_7
  )
    ],
  },
  {
    path: '/magic-link',
    /* internal name: '/magic-link' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':hash',
        name: 'MagicLink',
        component: () => import('/tmp/build_a585c7f8/src/pages/magic-link/[hash].vue'),
        /* no children */
      },
  _definePage_default_8
  )
    ],
  },
  {
    path: '/nets',
    name: '/nets',
    component: () => import('/tmp/build_a585c7f8/src/pages/nets.vue'),
    /* no children */
  },
  {
    path: '/templates',
    /* internal name: '/templates' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'management',
        name: 'TemplateManagement',
        component: () => import('/tmp/build_a585c7f8/src/pages/templates/management.vue'),
        /* no children */
      },
  _definePage_default_9
  )
    ],
  },
  {
    path: '/verify',
    /* internal name: '/verify' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':hash',
        name: 'Verify',
        component: () => import('/tmp/build_a585c7f8/src/pages/verify/[hash].vue'),
        /* no children */
      },
  _definePage_default_10
  )
    ],
  }
]
