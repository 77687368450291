<template>
  <div class="sovi-contracts pb-4 pt-7 md:pt-3">
    <sovi-bread-crumbs class="pb-2" />

    <div
      class="grid items-start gap-4"
      :class="{ 'lg:grid-cols-[1fr_380px] xl:grid-cols-[1fr_460px]': isAuthor }"
    >
      <sovi-card ref="contractInfo" class="contract-details overflow-visible">
        <div class="grid pt-4 md:pt-6">
          <sovi-contract-header
            id="contract-info"
            class="contract-section"
            @init="onInit()"
          />

          <div
            ref="addDocumentsRef"
            data-step="documents"
            :class="{
              'pointer-events-none z-[11] -mx-4 rounded-xl bg-light-200 px-4 transition-all dark:bg-dark-700':
                isStepActive('newContractTour', 'documents'),
            }"
          >
            <sovi-contract-documents class="contract-section" />
          </div>

          <div
            ref="addParticipantsRef"
            data-step="participants"
            :class="{
              'pointer-events-none z-[11] -mx-4 rounded-xl bg-light-200 px-4 transition-all dark:bg-dark-700':
                isStepActive('newContractTour', 'participants'),
            }"
          >
            <sovi-contract-signatures class="contract-section" />
          </div>
        </div>

        <template #actions>
          <sovi-contract-actions />
        </template>
      </sovi-card>

      <sovi-contract-timeline v-if="isAuthor" />
    </div>

    <sovi-contract-delete-dialog
      :display="showRemoveContractDialog"
      :item="{ ...contract, id: contractId }"
      @close="showRemoveContractDialog = false"
      @delete="onDelete()"
    />

    <sovi-contract-archive-dialog
      :display="showArchiveContractDialog"
      :item="{ ...contract, id: contractId }"
      @close="showArchiveContractDialog = false"
      @archive="onArchive()"
    />

    <sovi-contract-restore-dialog
      :display="showRestoreContractDialog"
      :item="{ ...contract, id: contractId }"
      @close="showRestoreContractDialog = false"
      @restore="onRestore()"
    />

    <sovi-participants-add-dialog />

    <sovi-contract-add-dialog v-if="initialized" />

    <sovi-first-contract-dialog
      v-if="contract.status === 'created'"
      :display="showFirstContractDialog"
      @cancel="showFirstContractDialog = false"
      @submit="showFirstContractDialog = false"
    />
  </div>
</template>

<script lang="ts" setup>
import { useContract } from '@/composables/useContract';
import { useAppStore } from '@/stores/app';
import { useContractStore } from '@/stores/contract';
import { useOnboardingStore } from '@/stores/onboarding';
// import { TourStep } from '@/utils';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import type SoviContractDocuments from '@/components/contract/SoviContractDocuments.vue';
import { definePage } from 'vue-router/auto';
import { useRouter } from 'vue-router';

definePage({
  name: 'Contract',
  meta: {
    preloaders: [
      'preloadRoles',
      'preloadParticipants',
      'preloadDocuments',
      'preloadLogos',
      'preloadContractTemplates',
    ],
    hero: true,
    restricted: true,
    tour: 'newContractTour',
  },
});

const initialized = ref(false);

const addDocumentsRef = ref<HTMLElement | null>(null);

const addParticipantsRef = ref<HTMLElement | null>(null);

const { showFirstContractDialog } = storeToRefs(useOnboardingStore());

const { isStepActive } = useOnboardingStore();

const { loading } = storeToRefs(useAppStore());

const { isAuthor, contractId, contract } = useContract();

const {
  showRemoveContractDialog,
  showArchiveContractDialog,
  showRestoreContractDialog,
} = storeToRefs(useContractStore());

const router = useRouter();

const onRestore = () => {
  showRestoreContractDialog.value = false;

  router.replace({ name: 'Contracts' });
};

const onDelete = () => {
  showRemoveContractDialog.value = false;

  router.replace({ name: 'Contracts' });
};

const onArchive = () => {
  showArchiveContractDialog.value = false;

  router.replace({ name: 'Contracts' });
};

watch(
  contractId,
  (id) => {
    if (!id) return;

    document
      .getElementById('sovi-stripe')
      ?.scrollIntoView({ behavior: 'smooth' });
  },
  { immediate: true },
);

const onInit = () => {
  initialized.value = true;
  loading.value = false;
};
</script>

<style lang="scss">
.contract-section + .contract-section {
  @apply border-t dark:border-t-dark-600 border-t-light-400;
}
</style>
