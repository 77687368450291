import { useAuthorizationStore } from '@/stores/authorization';
import { useContractStore } from '@/stores/contract';
import { useParticipantStore } from '@/stores/participant';
import { ContractStatus } from '@/types/contract';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export const useContract = () => {
  const route = useRoute();

  const { isAuthor } = storeToRefs(useParticipantStore());

  const { getContractById } = storeToRefs(useContractStore());

  const { user } = storeToRefs(useAuthorizationStore());

  const contractId = computed(
    () => (route.params?.contractId as string) ?? null,
  );

  const contract = computed(() => getContractById.value(contractId.value));

  const isContractSent = computed(() =>
    [
      ContractStatus.Sent,
      ContractStatus.Finished,
      ContractStatus.Failed,
    ].includes(contract.value.status),
  );

  const isContractArchiveable = computed(
    () =>
      [ContractStatus.Finished, ContractStatus.Failed].includes(
        contract.value.status,
      ) && +(contract.value?.folder_id || 0) !== 1,
  );

  const isContractRestoreable = computed(
    () => +(contract.value?.folder_id || 0) === 1,
  );

  const isContractFinished = computed(
    () => contract.value.status === ContractStatus.Finished,
  );

  const isContractFailed = computed(
    () => contract.value.status === ContractStatus.Failed,
  );

  const isCompanyUser = computed(() => +(user.value?.is_company || 0) === 1);

  return {
    contract,
    contractId,
    isAuthor,
    isContractFailed,
    isContractFinished,
    isContractSent,
    isCompanyUser,
    isContractArchiveable,
    isContractRestoreable,
  };
};
