<template>
  <div class="sovi-app relative" :class="{ dark: theme.current.value.dark }">
    <v-app
      id="sovi-main"
      class="overflow-auto bg-gradient-light bg-cover md:max-h-lvh dark:bg-gradient-dark"
    >
      <suspense>
        <v-main class="flex flex-col">
          <div v-auto-animate>
            <template v-if="showHero && !isLoading('sovi-fullscreen-loader')">
              <sovi-stripe id="sovi-stripe" />

              <sovi-header class="p-0 md:mb-4 md:mt-6 md:pb-3" />
            </template>
          </div>

          <div
            :class="{
              'sovi-main-container': !showHero,
              'sovi-max-container': isMaxContainer,
            }"
            class="relative mx-auto w-screen max-w-screen-3xl flex-1 px-2 pt-0 sm:px-4 md:px-8"
          >
            <router-view v-slot="{ Component }">
              <transition name="fade" mode="out-in">
                <component :is="Component" />
              </transition>
            </router-view>
          </div>

          <sovi-footer :transparent="!isHome" />
        </v-main>
      </suspense>

      <sovi-loader v-if="isLoading('sovi-fullscreen-loader')" />

      <sovi-package-dialog v-model="showDialog" />

      <sovi-toasts
        :is-last-event-visible="isLastEventVisible"
        @close="isLastEventVisible = false"
      />

      <sovi-snackbar />

      <sovi-signed-in-another-tab-dialog
        :display="signedInInAnotherTab"
        @close="signedInInAnotherTab = false"
      />

      <sovi-login-expired-dialog
        :display="showExpirationDialog"
        @close="showExpirationDialog = false"
      />

      <sovi-onboarding />
    </v-app>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted, inject } from 'vue';
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useAuthorizationStore } from '@/stores/authorization';
import { useRoute, useRouter } from 'vue-router';
import { useCreditStore } from '@/stores/credit';
import { useContractStore } from '@/stores/contract';
import { useDocumentStore } from '@/stores/document';
import { useEventStore } from '@/stores/event';
import { useNotificationStore } from '@/stores/notification';
import { useTheme } from 'vuetify';
import type { Socket } from 'socket.io-client';
import { socketIoInjectionKey } from '@/plugins/socket.io';
import { usePackageStore } from './stores/package';
import { useLoadingStore } from './stores/loading';

const socket = inject<Socket>(socketIoInjectionKey);

const route = useRoute();

const router = useRouter();

const theme = useTheme();

const { showHero, showExpirationDialog } = storeToRefs(useAppStore());

const { user } = storeToRefs(useAuthorizationStore());

const { showDialog } = storeToRefs(usePackageStore());

const { getCurrentCredit } = useCreditStore();

const { total } = storeToRefs(useCreditStore());

const { getContract, list: listContracts } = useContractStore();

const { listDocuments } = useDocumentStore();

const { getEvents, getContractEvents } = useEventStore();

const { events } = storeToRefs(useEventStore());

const { queue } = storeToRefs(useNotificationStore());

const { isAuthorized } = useAuthorizationStore();

const { isLoading } = useLoadingStore();

const isLastEventVisible = ref(false);

const signedInInAnotherTab = ref(false);

const socketPoller = ref<ReturnType<typeof setInterval> | null>(null);

const isMaxContainer = computed(() => {
  return !!route.meta.maxContainer;
});

const isHome = computed(() => {
  return route.name === 'Login';
});

const subscriptions = ref<Set<string>>(new Set());

const subscribe = (topic: string) => {
  subscriptions.value.add(topic);

  if (socket?.connected) {
    socket?.emit('subscribe', [topic]);
  }
};

const unsubscribe = (topic: string) => {
  const deleted = subscriptions.value.delete(topic);

  if (deleted && socket?.connected) {
    socket.removeAllListeners(topic);
  }
};

const handleExpiration = (userId: number) => {
  console.log(
    'subscribe to ',
    `SOCKET_UPDATE_EXPIRE_${localStorage.getItem('sovi-identifier')}-${userId}`,
  );

  const expirationEvent = `SOCKET_UPDATE_EXPIRE_${localStorage.getItem('sovi-identifier')}-${userId}`;

  subscribe(expirationEvent);

  socket?.on(expirationEvent, async () => {
    console.log('expiring', expirationEvent);

    total.value = null;
    events.value = [];
    queue.value = [];

    subscriptions.value.forEach((subscription) => {
      unsubscribe(subscription);
    });

    subscriptions.value.clear();

    if (route.name !== 'Login') {
      router.push('/');
    }

    await isAuthorized();

    if (user.value?.id) {
      signedInInAnotherTab.value = true;
    }
  });
};

watch(
  user,
  async (value) => {
    if (!value?.id) return;

    await getCurrentCredit();

    subscribe(`SOCKET_UPDATE_CONTRACTS_${value.id}`);
    subscribe(`SOCKET_UPDATE_EVENTS_${value.id}`);
    subscribe(`SOCKET_UPDATE_PAYMENT_${value.id}`);

    handleExpiration(value.id);

    socket?.on(`SOCKET_UPDATE_CONTRACTS_${value.id}`, async (data) => {
      // If currently in a contract, update the details
      if (route.name === 'Contract') {
        await getContract({
          contractId: route.params.contractId as string,
        });

        setTimeout(() => {
          listDocuments(route.params.contractId as string);
        }, 2000);

        await getContractEvents(route.params.contractId as string);
      }

      // If currently in contract listing, get all contracts
      if (route.name === 'Contracts') {
        await listContracts({
          page: 1,
          limit: 25,
          folderId: route.params.folderId as string,
        });
      }

      // Get latest events to the mailbox
      await getEvents({ forceFetch: true, paginate: false });

      isLastEventVisible.value = true;

      console.log('SOCKET_UPDATE_CONTRACTS', data);
    });

    socket?.on(`SOCKET_UPDATE_EVENTS_${value.id}`, async (data) => {
      await getEvents({ forceFetch: true, paginate: false });

      if (route.name === 'Contract') {
        listDocuments(route.params.contractId as string);

        await getContractEvents(route.params.contractId as string);

        events.value = events.value.map((event) => {
          if (event.hash === route.params?.contractId) {
            return { ...event, acknowledged: 1 };
          }

          return event;
        });
      }

      isLastEventVisible.value = true;

      console.log('SOCKET_UPDATE_EVENTS', data);
    });

    socket?.on(`SOCKET_UPDATE_PAYMENT_${value.id}`, async () => {
      await getCurrentCredit(true);
    });
  },
  { immediate: true },
);

onMounted(() => {
  socket?.on('connect', () => {
    if (subscriptions.value.size) {
      socket?.emit('subscribe', [...subscriptions.value]);
    }
  });

  socket?.connect();

  socketPoller.value = setInterval(() => {
    if (!socket?.connected) {
      socket?.connect();
    }
  }, 3000);
});

onUnmounted(() => {
  clearInterval(socketPoller.value as ReturnType<typeof setInterval>);

  subscriptions.value.forEach((subscription) => {
    unsubscribe(subscription);
  });

  subscriptions.value.clear();
});
</script>

<style lang="scss">
html,
body {
  overflow: hidden;
  overflow-y: hidden;
  height: 100vh;
}

.sovi-app {
  height: 100%;
  overflow: hidden;
  overflow-y: hidden;

  &__hero {
    // position: absolute !important;
    margin-bottom: -70px;
  }

  .sovi-main-container {
    min-height: 100%;
    // height: 100%;
  }

  .sovi-max-container {
    min-height: 100%;
    // height: 100%;
    max-width: 100lvw;
    padding: 0;
  }
}

@media (max-width: 768px) {
  html {
    height: 100vh !important;
    overflow: auto !important;
  }

  html.overflow-hidden {
    overflow: hidden !important;
  }

  body {
    height: unset !important;
  }

  .sovi-app {
    height: unset !important;

    #sovi-main {
      height: unset !important;

      > div {
        height: fit-content !important;
      }
    }

    .sovi-main-container {
      min-height: unset !important;
      max-height: unset !important;
      height: unset !important;
    }
  }
}

.pulse-border {
  animation: 4s ease-in-out 2s infinite pulse;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(236, 161, 194, 0.7);
  }

  35% {
    box-shadow: 0 0 0 10px rgba(236, 161, 194, 0);
  }

  50% {
    box-shadow: 0 0 0 0 rgba(236, 161, 194, 0);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
