import Client from '@/repositories/clients/fetchClient';
import type {
  Contract,
  CreateContractPayload,
  CreateContractResponse,
  CreateFolderPayload,
  CreateFolderResponse,
  DeleteFolderPayload,
  DeleteFolderResponse,
  GetContractPayload,
  GetContractsResponse,
  GetLogosResponse,
  MessageResponse,
  MoveFolderPayload,
  SearchContractsResult,
  UpdateContractResponse,
  UpdateFolderPayload,
  UpdateFolderResponse,
} from '@/types/contract';

const resource = 'contract';

export const contractRepository = {
  create: (payload: CreateContractPayload) =>
    Client.post<CreateContractResponse>(resource, payload),

  createFolder: (payload: CreateFolderPayload) =>
    Client.post<CreateFolderResponse>(`${resource}/folder`, payload),

  delete: (contractId: string) =>
    Client.delete<{ message: string }>(`${resource}/${contractId}`),

  archive: (contractId: string, payload: { archive: number }) =>
    Client.put<{ message: string }>(
      `${resource}/archive/${contractId}`,
      payload,
    ),

  deleteFolder: (payload: DeleteFolderPayload) =>
    Client.delete<DeleteFolderResponse>(`${resource}/folder`, payload),

  getAll: (folderId: string | null, page: number, limit: number) =>
    Client.get<GetContractsResponse>(
      `${resource}${
        folderId ? `/folder/${folderId}` : ''
      }?page=${page}&limit=${limit}`,
    ),

  getFolder: (folderId: string) =>
    Client.get(`${resource}/folder-content/${folderId}`),

  getFolderTree: (participantId: string) =>
    Client.get(`${resource}/folder-tree/${participantId}`),

  getLogos: (id: string) =>
    Client.get<GetLogosResponse>(`${resource}/logos/${id}`),

  getOne: (id: string) => Client.get<GetContractPayload>(`${resource}/${id}`),

  moveToFolder: (payload: MoveFolderPayload) =>
    Client.put<MessageResponse>(`${resource}/folder-move`, payload),

  search: (payload: { searchString: string }) =>
    Client.post<SearchContractsResult>(`${resource}/search`, payload),

  update: (constractId: string, payload: { contract: Partial<Contract> }) =>
    Client.put<UpdateContractResponse>(`${resource}/${constractId}`, payload),

  updateFolder: (payload: UpdateFolderPayload) =>
    Client.put<UpdateFolderResponse>(`${resource}/folder`, { folder: payload }),
};
