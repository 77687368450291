import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import type {
  Contract,
  Folder,
  FolderMoveDialogParams,
  ListContractsPayload,
  MoveFolderPayload,
} from '@/types/contract';
import { contractRepository } from '@/repositories';
import { useLoadingStore } from '@/stores/loading';
import type { BreadCrumb } from '@/types/breadCrumbs';
import type { Logo } from '@/types/document';
import type { ContractTemplate } from '@/types/template';

export const useContractStore = defineStore('contract', () => {
  const breadCrumbs = ref<BreadCrumb[]>([]);

  const folders = ref<Folder[]>([]);

  const contractTemplates = ref<ContractTemplate[]>([]);

  const contracts = ref<Record<string, Contract>>({});

  const showAddContractDialog = ref(false);

  const showRemoveContractDialog = ref(false);

  const showArchiveContractDialog = ref(false);

  const showRestoreContractDialog = ref(false);

  const logos = ref<Logo[]>([]);

  const folderMoveDialog = ref<FolderMoveDialogParams>({
    show: false,
    source: null,
    target: null,
    type: 'folder',
  });

  const contractCount = ref(0);

  const getContractById = computed(
    () => (contractId: string) => contracts.value[contractId] ?? {},
  );

  const { startLoading, endLoading } = useLoadingStore();

  /**
   * Creates a new contract
   */
  const createContract = async ({
    parentId,
    contract,
    owner,
  }: {
    owner: 'private' | number;
    parentId: string;
    contract: { contractName: string; authorName: string };
  }) => {
    startLoading('createContract');

    const {
      data: { contract: newContract },
    } = await contractRepository.create({
      contract: contract.contractName,
      name: contract.authorName || '',
      parentId,
      owner,
    });

    endLoading('createContract');

    return newContract.id;
  };

  const deleteContract = async ({ id }: { id: string }) => {
    startLoading('deleteContract');

    await contractRepository.delete(id);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { [id]: _, ...contractsToKeep } = contracts.value;

    contracts.value = { ...contractsToKeep };

    endLoading('deleteContract');
  };

  const archiveContract = async ({ id }: { id: string }) => {
    startLoading('archiveContract');

    await contractRepository.archive(id, { archive: 1 });

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { [id]: _, ...contractsToKeep } = contracts.value;

    contracts.value = { ...contractsToKeep };

    endLoading('archiveContract');
  };

  const restoreContract = async ({ id }: { id: string }) => {
    startLoading('restoreContract');

    await contractRepository.archive(id, { archive: 0 });

    endLoading('restoreContract');
  };

  const updateContract = async (
    payload: Partial<Contract> & Pick<Contract, 'id'>,
  ) => {
    startLoading('updateContract');

    const { id, ...data } = payload;

    const {
      data: { contract: updatedContract },
    } = await contractRepository.update(id, {
      contract: data,
    });

    contracts.value = {
      ...contracts.value,
      [updatedContract[0].hash]: updatedContract[0],
    };

    endLoading('updateContract');

    return updatedContract[0].id;
  };

  const createFolder = async ({
    folder,
    parentId,
  }: {
    folder: { name: string };
    parentId: string;
  }) => {
    try {
      startLoading('createFolder');

      const {
        data: {
          folder: { id },
        },
      } = await contractRepository.createFolder({
        folder,
        parentId,
      });

      folders.value.push({
        id,
        name: folder.name,
        type: 'folder',
        parent_id: parentId,
      });

      endLoading('createFolder');
    } catch (error) {
      console.error(error);

      endLoading('createFolder');
    }
  };

  const updateFolder = async (folder: { id: string; name: string }) => {
    try {
      startLoading('updateFolder');

      await contractRepository.updateFolder(folder);

      folders.value = folders.value.map((f) => ({
        ...f,
        name: f.id === folder.id ? folder.name : f.name,
      }));

      endLoading('updateFolder');
    } catch (error) {
      console.error(error);

      endLoading('updateFolder');
    }
  };

  const deleteFolder = async ({ id }: Folder) => {
    try {
      startLoading('deleteFolder');

      await contractRepository.deleteFolder({ id });

      endLoading('deleteFolder');
    } catch (error) {
      console.error(error);

      endLoading('deleteFolder');
    }
  };

  const moveToFolder = async (params: MoveFolderPayload) => {
    try {
      startLoading('moveToFolder');

      await contractRepository.moveToFolder(params);

      if (params.type === 'folder') {
        folders.value = folders.value.filter(
          (folder) => folder.id !== params.item.id,
        );

        endLoading('moveToFolder');

        return;
      }

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { [params.item.id]: _, ...contractsToKeep } = contracts.value;

      contracts.value = { ...contractsToKeep };

      contractCount.value -= 1;

      endLoading('moveToFolder');
    } catch (error) {
      console.error(error);

      endLoading('moveToFolder');
    }
  };

  const setContracts = ({
    items,
    merge = false,
  }: {
    items: (Partial<Contract> & Pick<Contract, 'id'>)[];
    merge?: boolean;
  }) => {
    if (!items.length) return;

    const newContracts = items.reduce(
      (obj, item) => {
        obj[item.id] = item as Contract;

        return obj;
      },
      {} as Record<string, Contract>,
    );

    contracts.value = {
      ...(merge ? contracts.value : {}),
      ...newContracts,
    };
  };

  const setBreadCrumbs = (payload: Partial<BreadCrumb>[]) => {
    const rootText = 'Contracts';
    const translatable = true;

    if (payload && payload[0] === null) {
      return;
    }

    breadCrumbs.value = [
      ...[
        {
          href: '/contracts',
          text: rootText,
          name: 'Contracts',
          id: undefined,
          translatable,
        },
      ],
      ...payload.map((crumb) => ({
        href:
          (crumb.href as string) || !crumb.disabled
            ? `/contracts/${crumb.id}`
            : `/contract/${crumb.id}`,
        text: crumb.name as string,
        name: crumb.name as string,
        disabled: crumb.disabled || false,
        id: crumb.id,
        translatable: !crumb.id || crumb.id === 1,
      })),
    ];
  };

  const getContract = async ({ contractId }: { contractId: string }) => {
    try {
      startLoading('getContract');

      const contract = await contractRepository.getOne(contractId);

      const { crumbs, ...data } = contract.data;

      setContracts({ items: data ? [data] : [], merge: true });

      setBreadCrumbs([
        ...crumbs,
        {
          name: data.name,
          disabled: true,
          id: data.id,
        },
      ]);

      endLoading('getContract');
    } catch (error) {
      console.error(error);
      endLoading('getContract');
    }
  };

  const getLogos = async (contractId: string | null) => {
    try {
      if (contractId === null) {
        logos.value = [];

        return;
      }

      const response = await contractRepository.getLogos(contractId);

      logos.value = response.data || [];
    } catch (error) {
      console.error(error);
    }
  };

  const list = async (params?: Partial<ListContractsPayload>) => {
    try {
      startLoading('getContracts');

      const {
        folderId = null,
        replaceState = true,
        page = 1,
        limit = 5,
        merge = false,
      } = params || {};

      const {
        data: { contracts: constractList, folders: folderList, crumbs, total },
      } = await contractRepository.getAll(folderId, page, limit);

      if (!replaceState) {
        endLoading('getContracts');

        return { contracts: constractList, folders: folderList };
      }

      if (!merge) {
        contracts.value = {};
        folders.value = [];
      }

      folders.value = folderList.map((folder) => ({
        ...folder,
        type: 'folder',
      }));

      setContracts({ items: constractList, merge });
      setBreadCrumbs(crumbs);
      contractCount.value = total;

      endLoading('getContracts');

      return { contracts: constractList, folders: folderList, crumbs };
    } catch (error) {
      endLoading('getContracts');

      console.error(error);

      return { contracts: [], folders: [], crumbs: [] };
    }
  };

  const search = async (searchString: string) => {
    try {
      startLoading('searchContracts');

      const { success, data } = await contractRepository.search({
        searchString,
      });

      // on abort
      if (!success) return [];

      const { contracts: items } = data;

      endLoading('searchContracts');

      return items;
    } catch (error) {
      console.error(error);

      endLoading('searchContracts');

      return [];
    }
  };

  return {
    breadCrumbs,
    folders,
    contracts,
    showAddContractDialog,
    showRemoveContractDialog,
    showArchiveContractDialog,
    showRestoreContractDialog,
    logos,
    folderMoveDialog,
    contractCount,
    getContract,
    getContractById,
    createContract,
    deleteContract,
    archiveContract,
    restoreContract,
    updateContract,
    createFolder,
    updateFolder,
    deleteFolder,
    moveToFolder,
    getLogos,
    list,
    search,
    setBreadCrumbs,
    contractTemplates,
  };
});
